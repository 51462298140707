<template>
  <div class="card card-lightblue card-outline collapsed-card">
    <div class="card-header pt-2 pb-2">
      <!-- <h3 class="card-title">
        <i class="fa fa-truck text-dark"></i> Reporte Trabajo
      </h3> -->
      <h3 class="card-title text-black text-bold">
        <i class="fa fa-truck text-navy"></i> Reporte Trabajo Conductor:
        {{
          $parent.servicioInterno.persona
            ? $parent.servicioInterno.persona.nombres +
              " " +
              $parent.servicioInterno.persona.apellidos
            : ""
        }}
      </h3>
      <button
        type="button"
        class="btn bg-frontera text-white"
        @click="addDetalle('normal')"
        :disabled="
          estado_detalle == 1 ||
            !$parent.servicioInterno.fecha_fin1 ||
            !$parent.servicioInterno.hora_fin1 ||
            fin_reportes
        "
      >
        <i class="fas fa-plus" title="Añadir"></i>
      </button>
      <div class="card-tools">
        <button
          type="button"
          data-card-widget="collapse"
          data-toggle="tooltip"
          title="Collapse"
          class="btn btn-tool"
        >
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <div class="card-body" v-if="csDetalles.length > 0 || estado_detalle == 1">
      <div
        class="card-title text-info lg"
        v-if="csDetallesExtras.length > 0 || estado_detalle_extra == 1"
      >
        <i class="fa fa-user-clock"></i> Hora Normal
      </div>
      <!-- tabla detalles de horas normales -->

      <table class="table table-bordered table-striped table-hover table-sm">
        <thead class="bg-dark">
          <tr class="text-center">
            <th class="col-3">Hora Inicio</th>
            <th class="col-3">Hora Fin</th>
            <th class="col-1">Duración <small>(horas)</small></th>
            <th class="col-2">Tipo Tiempo</th>
            <th class="col-2">Observaciones</th>
            <th
              class="col-1"
              v-if="
                $store.getters.can(
                  'cs.revisionServiciosInternos.editReporteTrabajo'
                )
              "
            >
              Acciones
            </th>
          </tr>
        </thead>
        <tbody id="tbody">
          <tr v-for="(csDetalle, indice) in csDetalles" :key="indice">
            <td class="text-center">
              <div
                class="row"
                v-if="id_actualizar_detalle == indice && estado_detalle == 4"
              >
                <div class="col-6">
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="servicioDetalle.fecha_ini"
                    :class="
                      $v.servicioDetalle.fecha_ini.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    :disabled="indice < csDetalles.length - 1"
                    @input="validaHoras(indice, 'normal')"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="servicioDetalle.hora_ini"
                    placeholder="hora_ini"
                    label="Hora Inicial"
                    class="form-control form-control-sm"
                    :class="
                      $v.servicioDetalle.hora_ini.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '23',
                    }"
                    :disabled="indice < csDetalles.length - 1"
                    @input="validaHoras(indice, 'normal')"
                  />
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-6">
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="csDetalle.fecha_ini"
                    disabled
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="csDetalle.hora_ini"
                    placeholder="hora_ini"
                    label="Hora Inicial"
                    class="form-control form-control-sm"
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '23',
                    }"
                    disabled
                  />
                </div>
              </div>
            </td>
            <td class="text-center">
              <div
                class="row"
                v-if="
                  id_actualizar_detalle == indice &&
                    estado_detalle == 4 &&
                    indice < csDetalles.length - 1
                "
              >
                <div class="col-6">
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="servicioDetalle.fecha_fin"
                    :class="
                      $v.servicioDetalle.fecha_fin.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @input="validaHoras(indice, 'normal')"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="servicioDetalle.hora_fin"
                    placeholder="Hora Fin"
                    label="hora_fin"
                    class="form-control form-control-sm"
                    :class="
                      $v.servicioDetalle.hora_fin.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '23',
                    }"
                    @input="validaHoras(indice, 'normal')"
                  />
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-6">
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="csDetalle.fecha_fin"
                    disabled
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="csDetalle.hora_fin"
                    placeholder="Hora Fin"
                    label="hora_fin"
                    class="form-control form-control-sm"
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '23',
                    }"
                    disabled
                  />
                </div>
              </div>
            </td>
            <td class="text-center text-nowrap">
              <div v-if="csDetalle.tiempo">
                <div v-if="csDetalle.tiempo.cant_horas_mostrar">
                  {{ csDetalle.tiempo.cant_horas_mostrar }}
                </div>
              </div>
              <div v-else>Sin establecer</div>
            </td>
            <td class="text-center">
              <div
                v-if="id_actualizar_detalle == indice && estado_detalle == 4"
              >
                <select
                  id="tipo_tiempo"
                  class="form-control form-control-sm text-center"
                  v-model="servicioDetalle.tipo_tiempo"
                  :class="
                    $v.servicioDetalle.tipo_tiempo.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_tiempo in listasForms.tipo_tiempos"
                    :key="tipo_tiempo.numeracion"
                    :value="tipo_tiempo.numeracion"
                  >
                    {{ tipo_tiempo.descripcion }}
                  </option>
                </select>
              </div>
              <div v-else>
                <select
                  id="tipo_tiempo"
                  class="form-control form-control-sm text-center"
                  v-model="csDetalle.tipo_tiempo"
                  disabled
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_tiempo in listasForms.tipo_tiempos"
                    :key="tipo_tiempo.numeracion"
                    :value="tipo_tiempo.numeracion"
                  >
                    {{ tipo_tiempo.descripcion }}
                  </option>
                </select>
              </div>
            </td>
            <td class="text-center">
              <div
                v-if="id_actualizar_detalle == indice && estado_detalle == 4"
              >
                <input
                  class="form-control form-control-sm"
                  v-model="servicioDetalle.observaciones"
                  :class="
                    $v.servicioDetalle.observaciones.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
              <div v-else>
                <input
                  class="form-control form-control-sm"
                  v-model="csDetalle.observaciones"
                  disabled
                />
              </div>
            </td>
            <td
              class="text-center"
              v-if="
                $parent.servicioInterno.estado == 1 ||
                  ($parent.servicioInterno.estado == 4 &&
                    $store.getters.can(
                      'cs.revisionServiciosInternos.editReporteTrabajo'
                    ))
              "
            >
              <div class="btn-group" v-if="estado_detalle != 4">
                <button
                  type="button"
                  class="btn bg-navy"
                  :disabled="estado_detalle != -1"
                  @click="editDetalle(indice, 'normal')"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  class="btn bg-danger"
                  @click="destroyDetalle(indice, 'normal')"
                  :disabled="estado_detalle != -1"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
              <div
                class="btn-group"
                v-if="estado_detalle == 4 && id_actualizar_detalle == indice"
              >
                <button
                  type="button"
                  class="btn bg-success"
                  v-show="!$v.servicioDetalle.$invalid"
                  @click="save(indice, 'normal')"
                >
                  <i class="fas fa-save"></i>
                </button>
                <button
                  class="btn bg-danger"
                  @click="cancelDetalle(indice, 'normal')"
                >
                  <i class="fa fa-ban"></i>
                </button>
              </div>
              <div
                class="btn-group"
                v-if="estado_detalle == 4 && id_actualizar_detalle !== indice"
              >
                <button
                  v-if="
                    $store.getters.can(
                      'cs.revisionServiciosInternos.editReporteTrabajo'
                    )
                  "
                  type="button"
                  class="btn bg-navy"
                  @click="editDetalle(indice, 'normal')"
                >
                  <i class="fas fa-edit"></i>
                </button>

                <button
                  class="btn bg-danger"
                  v-if="
                    $store.getters.can(
                      'cs.revisionServiciosInternos.editReporteTrabajo'
                    )
                  "
                  @click="destroyDetalle(indice, 'normal')"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="estado_detalle == 1">
            <td>
              <div class="row">
                <div class="col-6">
                  <input
                    type="date"
                    id="fecha_ini"
                    class="form-control form-control-sm"
                    v-model="servicioDetalle.fecha_ini"
                    :class="
                      $v.servicioDetalle.fecha_ini.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    disabled
                    @input="validaHoras(null, 'normal')"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="servicioDetalle.hora_ini"
                    placeholder="hora_ini"
                    label="Hora Inicial"
                    class="form-control form-control-sm"
                    :class="
                      $v.servicioDetalle.hora_ini.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '23',
                    }"
                    disabled
                    @input="validaHoras(null, 'normal')"
                  />
                </div>
              </div>
            </td>
            <td>
              <div class="row">
                <div class="col-6">
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="servicioDetalle.fecha_fin"
                    :class="
                      $v.servicioDetalle.fecha_fin.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @input="validaHoras(null, 'normal')"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="servicioDetalle.hora_fin"
                    placeholder="Hora Fin"
                    label="hora_final"
                    class="form-control form-control-sm"
                    :class="
                      $v.servicioDetalle.hora_fin.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '23',
                    }"
                    @input="validaHoras(null, 'normal')"
                  />
                </div>
              </div>
            </td>
            <td class="text-center text-muted text-nowrap">
              <small>Por establecer</small>
            </td>
            <td>
              <select
                id="tipo_tiempo"
                class="form-control form-control-sm"
                v-model="servicioDetalle.tipo_tiempo"
                :class="
                  $v.servicioDetalle.tipo_tiempo.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="tipo_tiempo in listasForms.tipo_tiempos"
                  :key="tipo_tiempo.numeracion"
                  :value="tipo_tiempo.numeracion"
                >
                  {{ tipo_tiempo.descripcion }}
                </option>
              </select>
            </td>
            <td>
              <input
                class="form-control form-control-sm"
                v-model="servicioDetalle.observaciones"
                :class="
                  $v.servicioDetalle.observaciones.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              />
            </td>
            <td>
              <div class="btn-group float-right">
                <button
                  class="btn bg-success"
                  v-if="!$v.servicioDetalle.$invalid"
                  @click="saveDetalle('normal')"
                >
                  <i class="fas fa-save"></i>
                </button>
                <button
                  class="btn bg-danger"
                  @click="cancelDetalle(null, 'normal')"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-if="datos_barra">
          <tr class="bg-light">
            <td colspan="6">
              <div class="progress">
                <div
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  class="progress-bar progress-bar-striped bg-success progress-bar-animated"
                  :style="
                    `width: ${
                      datos_barra.operativo.porcentaje != 'NaN'
                        ? datos_barra.operativo.porcentaje
                        : 0
                    }%`
                  "
                >
                  <b>
                    {{
                      datos_barra.operativo.tiempo.years
                        ? `Años: ${datos_barra.operativo.tiempo.years}`
                        : ""
                    }}
                    {{
                      datos_barra.operativo.tiempo.meses
                        ? `Meses: ${datos_barra.operativo.tiempo.meses}`
                        : ""
                    }}
                    {{
                      datos_barra.operativo.tiempo.dias
                        ? `Días: ${datos_barra.operativo.tiempo.dias}`
                        : ""
                    }}
                    {{
                      datos_barra.operativo.tiempo.horas
                        ? `Horas: ${datos_barra.operativo.tiempo.horas}`
                        : ""
                    }}
                    {{
                      datos_barra.operativo.tiempo.minutos
                        ? `Minutos: ${datos_barra.operativo.tiempo.minutos}`
                        : ""
                    }}
                    ({{
                      datos_barra.operativo.porcentaje != "NaN"
                        ? datos_barra.operativo.porcentaje
                        : 0
                    }}%)
                  </b>
                </div>
                <div
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  class="progress-bar progress-bar-striped bg-warning progress-bar-animated"
                  :style="
                    `width: ${
                      datos_barra.disponible.porcentaje != 'NaN'
                        ? datos_barra.disponible.porcentaje
                        : 0
                    }%`
                  "
                >
                  <b>
                    {{
                      datos_barra.disponible.tiempo.years
                        ? `Años: ${datos_barra.disponible.tiempo.years}`
                        : ""
                    }}
                    {{
                      datos_barra.disponible.tiempo.meses
                        ? `Meses: ${datos_barra.disponible.tiempo.meses}`
                        : ""
                    }}
                    {{
                      datos_barra.disponible.tiempo.dias
                        ? `Días: ${datos_barra.disponible.tiempo.dias}`
                        : ""
                    }}
                    {{
                      datos_barra.disponible.tiempo.horas
                        ? `Horas: ${datos_barra.disponible.tiempo.horas}`
                        : ""
                    }}
                    {{
                      datos_barra.disponible.tiempo.minutos
                        ? `Minutos: ${datos_barra.disponible.tiempo.minutos}`
                        : ""
                    }}
                    ({{
                      datos_barra.disponible.porcentaje != "NaN"
                        ? datos_barra.disponible.porcentaje
                        : 0
                    }}%)
                  </b>
                </div>
                <div
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  class="progress-bar progress-bar-striped bg-danger progress-bar-animated"
                  :style="
                    `width: ${
                      datos_barra.indisponible.porcentaje != 'NaN'
                        ? datos_barra.indisponible.porcentaje
                        : 0
                    }%`
                  "
                >
                  <b>
                    {{
                      datos_barra.indisponible.tiempo.years
                        ? `Años: ${datos_barra.indisponible.tiempo.years}`
                        : ""
                    }}
                    {{
                      datos_barra.indisponible.tiempo.meses
                        ? `Meses: ${datos_barra.indisponible.tiempo.meses}`
                        : ""
                    }}
                    {{
                      datos_barra.indisponible.tiempo.dias
                        ? `Días: ${datos_barra.indisponible.tiempo.dias}`
                        : ""
                    }}
                    {{
                      datos_barra.indisponible.tiempo.horas
                        ? `Horas: ${datos_barra.indisponible.tiempo.horas}`
                        : ""
                    }}
                    {{
                      datos_barra.indisponible.tiempo.minutos
                        ? `Minutos: ${datos_barra.indisponible.tiempo.minutos}`
                        : ""
                    }}
                    ({{
                      datos_barra.indisponible.porcentaje != "NaN"
                        ? datos_barra.indisponible.porcentaje
                        : 0
                    }}%)
                  </b>
                </div>
                <div
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  class="progress-bar progress-bar-striped bg-primary progress-bar-animated"
                  :style="
                    `width: ${
                      datos_barra.movilizacion.porcentaje != 'NaN'
                        ? datos_barra.movilizacion.porcentaje
                        : 0
                    }%`
                  "
                >
                  <b>
                    {{
                      datos_barra.movilizacion.tiempo.years
                        ? `Años: ${datos_barra.movilizacion.tiempo.years}`
                        : ""
                    }}
                    {{
                      datos_barra.movilizacion.tiempo.meses
                        ? `Meses: ${datos_barra.movilizacion.tiempo.meses}`
                        : ""
                    }}
                    {{
                      datos_barra.movilizacion.tiempo.dias
                        ? `Días: ${datos_barra.movilizacion.tiempo.dias}`
                        : ""
                    }}
                    {{
                      datos_barra.movilizacion.tiempo.horas
                        ? `Horas: ${datos_barra.movilizacion.tiempo.horas}`
                        : ""
                    }}
                    {{
                      datos_barra.movilizacion.tiempo.minutos
                        ? `Minutos: ${datos_barra.movilizacion.tiempo.minutos}`
                        : ""
                    }}
                    ({{
                      datos_barra.movilizacion.porcentaje != "NaN"
                        ? datos_barra.movilizacion.porcentaje
                        : 0
                    }}%)
                  </b>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- tabla detalles de horas extras -->
      <br />
      <div
        class="card-title text-info lg"
        v-if="csDetallesExtras.length > 0 || estado_detalle_extra == 1"
      >
        <i class="fa fa-user-clock"></i> Hora Extra
      </div>
      <table
        v-if="csDetallesExtras.length > 0 || estado_detalle_extra == 1"
        class="table table-bordered table-striped table-hover table-sm p-2"
      >
        <thead class="bg-dark">
          <tr class="text-center">
            <th class="col-3">Hora Inicio</th>
            <th class="col-3">Hora Fin</th>
            <th class="col-1">Duración <small>(horas)</small></th>
            <th class="col-2">Tipo Tiempo</th>
            <th class="col-2">Observaciones</th>
            <th
              class="col-1"
              v-if="
                $store.getters.can(
                  'cs.revisionServiciosInternos.editReporteTrabajo'
                )
              "
            >
              Acciones
            </th>
          </tr>
        </thead>
        <tbody id="tbody">
          <tr
            v-for="(csDetalleExtra, index) in csDetallesExtras"
            :key="csDetalleExtra.id"
          >
            <td class="text-center">
              <div
                class="row"
                v-if="
                  id_actualizar_detalle_extra == index &&
                    estado_detalle_extra == 2
                "
              >
                <div class="col-6">
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="servicioDetalleExtra.fecha_ini"
                    :class="
                      $v.servicioDetalleExtra.fecha_ini.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    disabled
                    @input="validaHoras(index, 'extra')"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="servicioDetalleExtra.hora_ini"
                    placeholder="hora_ini"
                    label="Hora Inicial"
                    class="form-control form-control-sm"
                    :class="
                      $v.servicioDetalleExtra.hora_ini.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '23',
                    }"
                    disabled
                    @input="validaHoras(index, 'extra')"
                  />
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-6">
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="csDetalleExtra.fecha_ini"
                    disabled
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="csDetalleExtra.hora_ini"
                    placeholder="hora_ini"
                    label="Hora Inicial"
                    class="form-control form-control-sm"
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '23',
                    }"
                    disabled
                  />
                </div>
              </div>
            </td>
            <td class="text-center">
              <div
                class="row"
                v-if="
                  id_actualizar_detalle_extra == index &&
                    estado_detalle_extra == 2
                "
              >
                <div class="col-6">
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="servicioDetalleExtra.fecha_fin"
                    :class="
                      $v.servicioDetalleExtra.fecha_fin.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @input="validaHoras(index, 'extra')"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="servicioDetalleExtra.hora_fin"
                    placeholder="Hora Fin"
                    label="hora_fin"
                    class="form-control form-control-sm"
                    :class="
                      $v.servicioDetalleExtra.hora_fin.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '23',
                    }"
                    @input="validaHoras(index, 'extra')"
                  />
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-6">
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="csDetalleExtra.fecha_fin"
                    disabled
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="csDetalleExtra.hora_fin"
                    placeholder="Hora Fin"
                    label="hora_fin"
                    class="form-control form-control-sm"
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '23',
                    }"
                    disabled
                  />
                </div>
              </div>
            </td>
            <td class="text-center text-nowrap">
              <div v-if="csDetalleExtra.tiempo">
                <div v-if="csDetalleExtra.tiempo.cant_horas_mostrar">
                  {{ csDetalleExtra.tiempo.cant_horas_mostrar }}
                </div>
              </div>
              <div v-else>Sin establecer</div>
            </td>
            <td class="text-center">
              <div
                v-if="
                  id_actualizar_detalle_extra == index &&
                    estado_detalle_extra == 2
                "
              >
                <select
                  id="tipo_tiempo"
                  class="form-control form-control-sm text-center"
                  v-model="servicioDetalleExtra.tipo_tiempo"
                  :class="
                    $v.servicioDetalleExtra.tipo_tiempo.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_tiempo in tipo_tiempos_extra"
                    :key="tipo_tiempo.numeracion"
                    :value="tipo_tiempo.numeracion"
                  >
                    {{ tipo_tiempo.descripcion }}
                  </option>
                </select>
              </div>
              <div v-else>
                <select
                  id="tipo_tiempo"
                  class="form-control form-control-sm text-center"
                  v-model="csDetalleExtra.tipo_tiempo"
                  disabled
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_tiempo in tipo_tiempos_extra"
                    :key="tipo_tiempo.numeracion"
                    :value="tipo_tiempo.numeracion"
                  >
                    {{ tipo_tiempo.descripcion }}
                  </option>
                </select>
              </div>
            </td>
            <td class="text-center">
              <div
                v-if="
                  id_actualizar_detalle_extra == index &&
                    estado_detalle_extra == 2
                "
              >
                <input
                  class="form-control form-control-sm"
                  v-model="servicioDetalleExtra.observaciones"
                  :class="
                    $v.servicioDetalleExtra.observaciones.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </div>
              <div v-else>
                <input
                  class="form-control form-control-sm"
                  v-model="csDetalleExtra.observaciones"
                  disabled
                />
              </div>
            </td>
            <td
              class="text-center"
              v-if="
                $store.getters.can(
                  'cs.revisionServiciosInternos.editReporteTrabajo'
                )
              "
            >
              <div class="btn-group" v-if="estado_detalle_extra != 2">
                <button
                  type="button"
                  class="btn bg-navy"
                  :disabled="estado_detalle_extra != -1"
                  @click="editDetalle(index, 'extra')"
                >
                  <i class="fas fa-edit"></i>
                </button>
              </div>
              <div
                class="btn-group"
                v-if="
                  estado_detalle_extra == 2 &&
                    id_actualizar_detalle_extra == index
                "
              >
                <button
                  type="button"
                  class="btn bg-success"
                  v-show="!$v.servicioDetalleExtra.$invalid"
                  @click="save(index, 'extra')"
                >
                  <i class="fas fa-save"></i>
                </button>
                <button
                  class="btn bg-danger"
                  @click="cancelDetalle(index, 'extra')"
                >
                  <i class="fa fa-ban"></i>
                </button>
              </div>
              <div
                class="btn-group"
                v-if="
                  estado_detalle_extra == 2 &&
                    id_actualizar_detalle_extra !== index
                "
              >
                <button
                  type="button"
                  class="btn bg-navy"
                  @click="editDetalle(index, 'extra')"
                >
                  <i class="fas fa-edit"></i>
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="estado_detalle_extra == 1">
            <td>
              <div class="row">
                <div class="col-6">
                  <input
                    type="date"
                    id="fecha_ini"
                    class="form-control form-control-sm"
                    v-model="servicioDetalleExtra.fecha_ini"
                    :class="
                      $v.servicioDetalleExtra.fecha_ini.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    disabled
                    @input="validaHoras(index, 'extra')"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="servicioDetalleExtra.hora_ini"
                    placeholder="hora_ini"
                    label="Hora Inicial"
                    class="form-control form-control-sm"
                    :class="
                      $v.servicioDetalleExtra.hora_ini.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '23',
                    }"
                    disabled
                    @input="validaHoras(index, 'extra')"
                  />
                </div>
              </div>
            </td>
            <td>
              <div class="row">
                <div class="col-6">
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="servicioDetalleExtra.fecha_fin"
                    :class="
                      $v.servicioDetalleExtra.fecha_fin.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @input="validaHoras(index, 'extra')"
                  />
                </div>
                <div class="col-6">
                  <input
                    type="text"
                    v-model="servicioDetalleExtra.hora_fin"
                    placeholder="Hora Fin"
                    label="hora_final"
                    class="form-control form-control-sm"
                    :class="
                      $v.servicioDetalleExtra.hora_fin.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    v-mask="{
                      mask: '99:99',
                      hourFormat: '23',
                    }"
                    @input="validaHoras(index, 'extra')"
                  />
                </div>
              </div>
            </td>
            <td class="text-center text-muted text-nowrap">
              <small>Por establecer</small>
            </td>
            <td>
              <select
                id="tipo_tiempo"
                class="form-control form-control-sm"
                v-model="servicioDetalleExtra.tipo_tiempo"
                :class="
                  $v.servicioDetalleExtra.tipo_tiempo.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="tipo_tiempo in tipo_tiempos_extra"
                  :key="tipo_tiempo.numeracion"
                  :value="tipo_tiempo.numeracion"
                >
                  {{ tipo_tiempo.descripcion }}
                </option>
              </select>
            </td>
            <td>
              <input
                class="form-control form-control-sm"
                v-model="servicioDetalleExtra.observaciones"
                :class="
                  $v.servicioDetalleExtra.observaciones.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              />
            </td>
            <td>
              <div class="btn-group float-right">
                <button
                  class="btn bg-success"
                  v-if="!$v.servicioDetalleExtra.$invalid"
                  @click="saveDetalle('extra')"
                >
                  <i class="fas fa-save"></i>
                </button>
                <button
                  class="btn bg-danger"
                  @click="cancelDetalle(null, 'extra')"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, helpers } from "vuelidate/lib/validators";
import moment from "moment";

const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);

export default {
  name: "CsRevisionReporteTrabajos",
  data() {
    return {
      servicioDetalle: {
        fecha_ini: null,
        hora_ini: null,
        fecha_fin: null,
        hora_fin: null,
        tipo_tiempo: null,
        observaciones: null,
      },
      servicioDetalleExtra: {
        fecha_ini: null,
        hora_ini: null,
        fecha_fin: null,
        hora_fin: null,
        tipo_tiempo: null,
        observaciones: null,
      },
      listasForms: {
        tipo_tiempos: {},
      },
      csDetalles: [],
      csDetallesExtras: [],
      fin_reportes: false,
      datos_barra: null,
      estado_detalle: -1,
      id_actualizar_detalle: -1,
      estado_detalle_extra: -1,
      id_actualizar_detalle_extra: -1,
      fechaLimite: false,
      tipo_tiempos_extra: [],
    };
  },

  validations: {
    /* validación de horas normales */
    servicioDetalle: {
      fecha_ini: {
        required,
      },
      hora_ini: {
        required,
        timeFormat,
      },
      fecha_fin: {
        required,
      },
      hora_fin: {
        required,
        timeFormat,
      },
      tipo_tiempo: {
        required,
      },
      observaciones: {
        required,
      },
    },
    /* validación de horas Extra */
    servicioDetalleExtra: {
      fecha_ini: {
        required,
      },
      hora_ini: {
        required,
        timeFormat,
      },
      fecha_fin: {
        required,
      },
      hora_fin: {
        required,
        timeFormat,
      },
      tipo_tiempo: {
        required,
      },
      observaciones: {
        required,
      },
    },
  },

  methods: {
    async getIndex(servicioInterno) {
      this.$parent.$parent.cargando = true;
      this.estado_detalle = -1;
      this.id_actualizar_detalle = -1;
      this.estado_detalle_extra = -1;
      this.id_actualizar_detalle_extra = -1;
      let me = this;
      me.estado_detalle = servicioInterno.estado;
      await axios
        .get("/api/cs/servicios/detalle", {
          params: {
            cs_servicio_interno_id: servicioInterno.id,
            conductor_id: servicioInterno.persona_id,
          },
        })
        .then(async (response) => {
          me.csDetalles = await response.data.filter(
            (det) => det.tipo_hora == 1
          );
          me.csDetallesExtras = await response.data.filter(
            (det) => det.tipo_hora == 2
          );
          // Se ajustan los valores para el formulario
          me.csDetalles.forEach((det) => {
            let inicio_f = det.hora_ini.split(" ");
            let fin_f = det.hora_fin.split(" ");
            det.fecha_ini = inicio_f[0];
            det.hora_ini = inicio_f[1];
            det.fecha_fin = fin_f[0];
            det.hora_fin = fin_f[1];
            det.tiempo = me.getTiempo(
              `${det.fecha_ini} ${det.hora_ini}`,
              `${det.fecha_fin} ${det.hora_fin}`
            );
          });

          me.csDetallesExtras.forEach((det) => {
            const inicio_f = det.hora_ini.split(" ");
            const fin_f = det.hora_fin.split(" ");
            det.fecha_ini = inicio_f[0];
            det.hora_ini = inicio_f[1];
            det.fecha_fin = fin_f[0];
            det.hora_fin = fin_f[1];
            det.tiempo = this.getTiempo(
              `${det.fecha_ini} ${det.hora_ini}`,
              `${det.fecha_fin} ${det.hora_fin}`
            );
          });
          await me.getDatosBarra();
          me.$parent.$parent.cargando = false;
          me.$parent.csDetalles = me.csDetalles;
          me.$parent.csDetallesExtras = me.csDetallesExtras;
          me.$parent.estado_detalle = me.estado_detalle;
          me.$parent.estado_detalle_extra = me.estado_detalle_extra;
          me.$parent.id_actualizar_detalle = me.id_actualizar_detalle;
          me.$parent.id_actualizar_detalle_extra = me.id_actualizar_detalle_extra;
          me.$parent.listasForms = me.listasForms;
          me.$parent.servicioDetalle = me.servicioDetalle;
          me.$parent.servicioDetalleExtra = me.servicioDetalleExtra;
        });
    },

    getTiempo(hora_ini, hora_fin) {
      if (hora_ini && hora_fin) {
        let inicio = moment(hora_ini);
        let fin = moment(hora_fin);
        let diferencia = fin.diff(inicio);

        // Se calcula la cantidad en horas según requerimiento de Frontera
        const cant_horas = diferencia / 1000 / 60 / 60;
        const cant_minutos = (cant_horas - parseInt(cant_horas)) * 10;

        return {
          years: moment.duration(diferencia).years(),
          meses: moment.duration(diferencia).months(),
          dias: moment.duration(diferencia).days(),
          horas: moment.duration(diferencia).hours(),
          minutos: moment.duration(diferencia).minutes(),
          cant_horas_mostrar:
            cant_minutos < 5 ? parseInt(cant_horas) : parseInt(cant_horas + 1),
        };
      }
      return null;
    },

    async getDatosBarra() {
      this.datos_barra = {
        operativo: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
        disponible: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
        indisponible: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
        movilizacion: {
          cant: 0,
          tiempo: {},
          porcentaje: 0,
        },
      };

      this.csDetalles.forEach((det) => {
        // Se calcula el tiempo del reporte
        const inicio_reporte = new Date(`${det.fecha_ini} ${det.hora_ini}`);
        const fin_reporte = new Date(`${det.fecha_fin} ${det.hora_fin}`);
        const tiempo_reporte = fin_reporte.getTime() - inicio_reporte.getTime();
        switch (det.tipo_tiempo) {
          case 1:
            this.datos_barra.operativo.cant += tiempo_reporte;
            break;
          case 2:
            this.datos_barra.disponible.cant += tiempo_reporte;
            break;
          case 3:
            this.datos_barra.indisponible.cant += tiempo_reporte;
            break;
          case 4:
            this.datos_barra.movilizacion.cant += tiempo_reporte;
            break;
          default:
            break;
        }
      });

      // Se calcula el tiempo del servicio
      const inicio_servicio = new Date(
        `${this.$parent.servicioInterno.fecha_ini1} ${this.$parent.servicioInterno.hora_ini1}`
      );
      let fin_servicio = null;
      if (this.$parent.conductor_2) {
        fin_servicio = new Date(
          `${this.$parent.servicioInterno.fecha_ini1} ${"18:00"}`
        );
      } else {
        fin_servicio = new Date(
          `${this.$parent.servicioInterno.fecha_fin1} ${this.$parent.servicioInterno.hora_fin1}`
        );
      }

      const tiempo_servicio =
        fin_servicio.getTime() - inicio_servicio.getTime();

      this.datos_barra.operativo.porcentaje = this.toFixed(
        (this.datos_barra.operativo.cant * 100) / tiempo_servicio
      );
      this.datos_barra.disponible.porcentaje = this.toFixed(
        (this.datos_barra.disponible.cant * 100) / tiempo_servicio
      );
      this.datos_barra.indisponible.porcentaje = this.toFixed(
        (this.datos_barra.indisponible.cant * 100) / tiempo_servicio
      );
      this.datos_barra.movilizacion.porcentaje = this.toFixed(
        (this.datos_barra.movilizacion.cant * 100) / tiempo_servicio
      );
      // Se asignan los tiempos
      this.datos_barra.operativo.tiempo = {
        years: moment.duration(this.datos_barra.operativo.cant).years(),
        meses: moment.duration(this.datos_barra.operativo.cant).months(),
        dias: moment.duration(this.datos_barra.operativo.cant).days(),
        horas: moment.duration(this.datos_barra.operativo.cant).hours(),
        minutos: moment.duration(this.datos_barra.operativo.cant).minutes(),
      };
      this.datos_barra.disponible.tiempo = {
        years: moment.duration(this.datos_barra.disponible.cant).years(),
        meses: moment.duration(this.datos_barra.disponible.cant).months(),
        dias: moment.duration(this.datos_barra.disponible.cant).days(),
        horas: moment.duration(this.datos_barra.disponible.cant).hours(),
        minutos: moment.duration(this.datos_barra.disponible.cant).minutes(),
      };
      this.datos_barra.indisponible.tiempo = {
        years: moment.duration(this.datos_barra.indisponible.cant).years(),
        meses: moment.duration(this.datos_barra.indisponible.cant).months(),
        dias: moment.duration(this.datos_barra.indisponible.cant).days(),
        horas: moment.duration(this.datos_barra.indisponible.cant).hours(),
        minutos: moment.duration(this.datos_barra.indisponible.cant).minutes(),
      };
      this.datos_barra.movilizacion.tiempo = {
        years: moment.duration(this.datos_barra.movilizacion.cant).years(),
        meses: moment.duration(this.datos_barra.movilizacion.cant).months(),
        dias: moment.duration(this.datos_barra.movilizacion.cant).days(),
        horas: moment.duration(this.datos_barra.movilizacion.cant).hours(),
        minutos: moment.duration(this.datos_barra.movilizacion.cant).minutes(),
      };
      
      this.$parent.datos_barra = this.datos_barra;
    },

    validaHoras(indice = null, tipo_hora) {
      if (!this.$v.servicioDetalle.hora_fin.$invalid && tipo_hora == "normal") {
        if (!this.$v.servicioDetalle.hora_fin.$invalid) {
          const fecha_final_servicio = new Date(
            `${this.$parent.servicioInterno.fecha_fin1} ${this.$parent.servicioInterno.hora_fin1}`
          );
          const fecha_inicial_reporte = new Date(
            `${this.servicioDetalle.fecha_ini} ${this.servicioDetalle.hora_ini}`
          );
          const fecha_final_reporte = new Date(
            `${this.servicioDetalle.fecha_fin} ${this.servicioDetalle.hora_fin}`
          );

          if (fecha_final_reporte <= fecha_final_servicio) {
            if (fecha_inicial_reporte >= fecha_final_reporte) {
              this.servicioDetalle.hora_fin = null;
              this.$swal({
                icon: "error",
                title: `Hora inicial del reporte es superior a la hora final del reporte...`,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            } else if (indice != null) {
              if (
                this.csDetalles.length > 1 &&
                this.csDetalles.length - 1 == indice
              ) {
                const fecha_ini_reporte_anterior = new Date(
                  `${this.csDetalles[indice - 1].fecha_ini} ${
                    this.csDetalles[indice - 1].hora_ini
                  }`
                );
                if (fecha_inicial_reporte <= fecha_ini_reporte_anterior) {
                  this.servicioDetalle.hora_ini = null;
                  this.$swal({
                    icon: "error",
                    title: `Hora inicial del reporte es inferior a la hora inicial del reporte anterior...`,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                  });
                } else {
                  this.csDetalles[indice - 1].fecha_fin = moment(
                    fecha_inicial_reporte.getTime()
                  ).format("YYYY-MM-DD");
                  this.csDetalles[indice - 1].hora_fin = moment(
                    fecha_inicial_reporte.getTime()
                  ).format("HH:mm");
                  this.csDetalles[indice - 1].tiempo = this.getTiempo(
                    `${this.csDetalles[indice - 1].fecha_ini} ${
                      this.csDetalles[indice - 1].hora_ini
                    }`,
                    `${this.csDetalles[indice - 1].fecha_fin} ${
                      this.csDetalles[indice - 1].hora_fin
                    }`
                  );
                  this.csDetalles[indice].tiempo = this.getTiempo(
                    fecha_inicial_reporte,
                    fecha_final_reporte
                  );
                }
              }
              if (this.csDetalles.length - 1 > indice) {
                const fecha_final_siguiente_reporte = new Date(
                  `${this.csDetalles[indice + 1].fecha_fin} ${
                    this.csDetalles[indice + 1].hora_fin
                  }`
                );
                if (fecha_final_siguiente_reporte <= fecha_final_reporte) {
                  this.servicioDetalle.hora_fin = null;
                  this.$swal({
                    icon: "error",
                    title: `Hora final del reporte es superior a la hora final del siguiente reporte...`,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                  });
                } else {
                  this.csDetalles[indice + 1].fecha_ini = moment(
                    fecha_final_reporte.getTime()
                  ).format("YYYY-MM-DD");
                  this.csDetalles[indice + 1].hora_ini = moment(
                    fecha_final_reporte.getTime()
                  ).format("HH:mm");
                  this.csDetalles[indice + 1].tiempo = this.getTiempo(
                    `${this.csDetalles[indice + 1].fecha_ini} ${
                      this.csDetalles[indice + 1].hora_ini
                    }`,
                    `${this.csDetalles[indice + 1].fecha_fin} ${
                      this.csDetalles[indice + 1].hora_fin
                    }`
                  );
                  this.csDetalles[indice].tiempo = this.getTiempo(
                    fecha_inicial_reporte,
                    fecha_final_reporte
                  );
                }
              }
            }
          } else {
            this.servicioDetalle.fecha_fin = this.$parent.servicioInterno.fecha_fin1;
            this.servicioDetalle.hora_fin = null;
            this.$swal({
              icon: "error",
              title: `Hora final del reporte es superior a la hora final del servicio...`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          }
        }
      }
      if (
        !this.$v.servicioDetalleExtra.hora_fin.$invalid &&
        tipo_hora == "extra"
      ) {
        const fecha_final_servicio_extra = new Date(
          `${this.$parent.servicioInterno.fecha_fin1} ${this.$parent.servicioInterno.hora_fin1}`
        );
        const fecha_inicial_reporte_extra = new Date(
          `${this.servicioDetalleExtra.fecha_ini} ${this.servicioDetalleExtra.hora_ini}`
        );
        const fecha_final_reporte_extra = new Date(
          `${this.servicioDetalleExtra.fecha_fin} ${this.servicioDetalleExtra.hora_fin}`
        );

        if (fecha_final_reporte_extra >= fecha_final_servicio_extra) {
          const tiempoLimiteHoras = 4;
          const diferenciaMilisegundos =
            fecha_final_reporte_extra - fecha_final_servicio_extra;

          // Convertir la diferencia a horas dividiendo por 3600000 (1 hora = 3600000 ms)
          const diferenciaHoras = diferenciaMilisegundos / 3600000;

          if (diferenciaHoras > tiempoLimiteHoras) {
            this.servicioDetalleExtra.hora_fin = null;
            this.$swal({
              icon: "error",
              title: `No es posible realizar más de 4 horas extra...`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          }
          if (fecha_inicial_reporte_extra >= fecha_final_reporte_extra) {
            this.servicioDetalleExtra.hora_fin = null;
            this.$swal({
              icon: "error",
              title: `Hora inicial del reporte es superior a la hora final del reporte...`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          } else if (indice != null) {
            if (this.csDetallesExtras.length - 1 > indice) {
              const fecha_final_siguiente_reporte_extra = new Date(
                `${this.csDetallesExtras[indice + 1].fecha_fin} ${
                  this.csDetallesExtras[indice + 1].hora_fin
                }`
              );
              if (
                fecha_final_siguiente_reporte_extra <= fecha_final_reporte_extra
              ) {
                this.servicioDetalleExtra.hora_fin = null;
                this.$swal({
                  icon: "error",
                  title: `Hora final del reporte es superior a la hora final del siguiente reporte...`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 4000,
                  timerProgressBar: true,
                });
              } else {
                this.csDetallesExtras[indice + 1].fecha_ini = moment(
                  fecha_final_reporte_extra.getTime()
                ).format("YYYY-MM-DD");
                this.csDetallesExtras[indice + 1].hora_ini = moment(
                  fecha_final_reporte_extra.getTime()
                ).format("HH:mm");
                this.csDetallesExtras[indice + 1].tiempo = this.getTiempo(
                  `${this.csDetallesExtras[indice + 1].fecha_ini} ${
                    this.csDetallesExtras[indice + 1].hora_ini
                  }`,
                  `${this.csDetallesExtras[indice + 1].fecha_fin} ${
                    this.csDetallesExtras[indice + 1].hora_fin
                  }`
                );
                this.csDetallesExtras[indice].tiempo = this.getTiempo(
                  fecha_inicial_reporte_extra,
                  fecha_final_reporte_extra
                );
              }
            }
          }
        } else {
          this.servicioDetalleExtra.fecha_fin = this.$parent.servicioInterno.fecha_fin1;
          this.servicioDetalleExtra.hora_fin = null;
          this.$swal({
            icon: "error",
            title: `Hora final del reporte extra es Inferior a la hora final del servicio normal...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
    },

    async getTipoTiempo() {
      await axios.get("/api/lista/144").then((response) => {
        this.listasForms.tipo_tiempos = response.data;
      });
      this.tipo_tiempos_extra = this.listasForms.tipo_tiempos.filter(
        (det) => det.numeracion != 3
      );
    },
    async editDetalle(index, tipo_hora) {
      if (tipo_hora == "normal") {
        this.id_actualizar_detalle = index;
        this.estado_detalle = 4;
        this.servicioDetalle = { ...this.csDetalles[index] };
        // await this.validaFinReportes();
        this.getDatosBarra();
      }
      if (tipo_hora == "extra") {
        this.id_actualizar_detalle_extra = index;
        this.estado_detalle_extra = 2;
        this.servicioDetalleExtra = { ...this.csDetallesExtras[index] };
        await this.validarFechaLimite();
      }
    },

    async cancelDetalle(index = null, tipo_hora) {
      if (tipo_hora == "normal") {
        this.estado_detalle = -1;

        // Se valida la fecha inicial del siguiente reporte
        if (index != null) {
          if (this.csDetalles.length - 1 > index) {
            const fecha_final_reporte = new Date(
              `${this.csDetalles[index].fecha_fin} ${this.csDetalles[index].hora_fin}`
            );
            this.csDetalles[index + 1].fecha_ini = moment(
              fecha_final_reporte.getTime() + 1000 * 60
            ).format("YYYY-MM-DD");
            this.csDetalles[index + 1].hora_ini = moment(
              fecha_final_reporte.getTime() + 1000 * 60
            ).format("HH:mm");
            this.csDetalles[index + 1].tiempo = this.getTiempo(
              `${this.csDetalles[index + 1].fecha_ini} ${
                this.csDetalles[index + 1].hora_ini
              }`,
              `${this.csDetalles[index + 1].fecha_fin} ${
                this.csDetalles[index + 1].hora_fin
              }`
            );
          }
          if (
            this.csDetalles.length > 1 &&
            this.csDetalles.length - 1 == index
          ) {
            const fecha_inicial_reporte = new Date(
              `${this.csDetalles[index].fecha_ini} ${this.csDetalles[index].hora_ini}`
            );
            this.csDetalles[index - 1].fecha_fin = moment(
              fecha_inicial_reporte.getTime() + 1000 * 60
            ).format("YYYY-MM-DD");
            this.csDetalles[index - 1].hora_fin = moment(
              fecha_inicial_reporte.getTime() + 1000 * 60
            ).format("HH:mm");
            this.csDetalles[index - 1].tiempo = this.getTiempo(
              `${this.csDetalles[index - 1].fecha_ini} ${
                this.csDetalles[index - 1].hora_ini
              }`,
              `${this.csDetalles[index - 1].fecha_fin} ${
                this.csDetalles[index - 1].hora_fin
              }`
            );
          }
          this.csDetalles[index].tiempo = this.getTiempo(
            `${this.csDetalles[index].fecha_ini} ${this.csDetalles[index].hora_ini}`,
            `${this.csDetalles[index].fecha_fin} ${this.csDetalles[index].hora_fin}`
          );
        }
        //await this.validaFinReportes();
        this.getDatosBarra();
      }
      if (tipo_hora == "extra") {
        this.estado_detalle_extra = -1;

        // Se valida la fecha inicial del siguiente reporte extra
        if (index != null) {
          if (this.csDetallesExtras.length - 1 > index) {
            const fecha_final_reporte = new Date(
              `${this.csDetallesExtras[index].fecha_fin} ${this.csDetallesExtras[index].hora_fin}`
            );
            this.csDetallesExtras[index + 1].fecha_ini = moment(
              fecha_final_reporte.getTime() + 1000 * 60
            ).format("YYYY-MM-DD");
            this.csDetallesExtras[index + 1].hora_ini = moment(
              fecha_final_reporte.getTime() + 1000 * 60
            ).format("HH:mm");
            this.csDetallesExtras[index + 1].tiempo = this.getTiempo(
              `${this.csDetallesExtras[index + 1].fecha_ini} ${
                this.csDetallesExtras[index + 1].hora_ini
              }`,
              `${this.csDetallesExtras[index + 1].fecha_fin} ${
                this.csDetallesExtras[index + 1].hora_fin
              }`
            );
            this.csDetallesExtras[index].tiempo = this.getTiempo(
              `${this.csDetallesExtras[index].fecha_ini} ${this.csDetallesExtras[index].hora_ini}`,
              `${this.csDetallesExtras[index].fecha_fin} ${this.csDetallesExtras[index].hora_fin}`
            );
          }
          await this.validarFechaLimite(index);
        }
      }
    },

    validarFechaLimite(index = null) {
      const fecha_final_servicio_extra = new Date(
        `${this.$parent.servicioInterno.fecha_fin1} ${this.$parent.servicioInterno.hora_fin1}`
      );
      const tiempoLimiteHoras = 4;

      if (index != null && index >= 0) {
        const fecha_final_reporte_extra = new Date(
          `${this.csDetallesExtras[index].fecha_fin} ${this.csDetallesExtras[index].hora_fin}`
        );
        const diferenciaMilisegundos =
          fecha_final_reporte_extra - fecha_final_servicio_extra;
        const diferenciaHoras = diferenciaMilisegundos / 3600000;
        if (diferenciaHoras == tiempoLimiteHoras) {
          this.fechaLimite = true;
        }
        if (diferenciaHoras < tiempoLimiteHoras) {
          this.fechaLimite = false;
        }
      } else {
        const fecha_final_reporte_extra = new Date(
          `${this.servicioDetalleExtra.fecha_fin} ${this.servicioDetalleExtra.hora_fin}`
        );
        const diferenciaMilisegundos =
          fecha_final_reporte_extra - fecha_final_servicio_extra;
        const diferenciaHoras = diferenciaMilisegundos / 3600000;

        if (diferenciaHoras == tiempoLimiteHoras) {
          this.fechaLimite = true;
        }
        if (diferenciaHoras < tiempoLimiteHoras) {
          this.fechaLimite = false;
        }
      }
    },

    /* validaFinReportes() {
      this.fin_reportes = false;
      if (this.csDetalles.length > 0) {
        const fin_servicio = new Date(
          `${this.$parent.servicioInterno.fecha_fin1} ${this.$parent.servicioInterno.hora_fin1}`
        );
        const fin_reporte = new Date(
          `${this.csDetalles[this.csDetalles.length - 1].fecha_fin} ${
            this.csDetalles[this.csDetalles.length - 1].hora_fin
          }`
        );
        if (
          fin_reporte.getTime() == fin_servicio.getTime() ||
          fin_servicio.getTime() - fin_reporte.getTime() < 1000 * 60
        ) {
          this.fin_reportes = true;
        }
      }
      this.getDatosBarra();
    }, */

    limpiarDetalle(tipo_hora) {
      if (tipo_hora == "normal") {
        this.servicioDetalle = {
          fecha_ini: null,
          hora_ini: null,
          fecha_fin: null,
          hora_fin: null,
          tipo_tiempo: null,
          cant_horas: null,
          observaciones: null,
        };
      }
      if (tipo_hora == "extra") {
        this.servicioDetalleExtra = {
          fecha_ini: null,
          hora_ini: null,
          fecha_fin: null,
          hora_fin: null,
          tipo_tiempo: null,
          cant_horas: null,
          observaciones: null,
        };
      }
    },

    save(indice, tipo_hora) {
      this.$parent.cargando = true;
      let data = {};
      if (tipo_hora == "normal") {
        data = {
          id: this.servicioDetalle.id,
          tipo_tiempo: this.servicioDetalle.tipo_tiempo,
          observaciones: this.servicioDetalle.observaciones,
          hora_ini: `${this.servicioDetalle.fecha_ini} ${this.servicioDetalle.hora_ini}`,
          hora_fin: `${this.servicioDetalle.fecha_fin} ${this.servicioDetalle.hora_fin}`,
          cant_horas: this.getTiempo(
            `${this.servicioDetalle.fecha_ini} ${this.servicioDetalle.hora_ini}`,
            `${this.servicioDetalle.fecha_fin} ${this.servicioDetalle.hora_fin}`
          ).cant_horas_mostrar,
        };
      }
      if (tipo_hora == "extra") {
        data = {
          id: this.servicioDetalleExtra.id,
          tipo_tiempo: this.servicioDetalleExtra.tipo_tiempo,
          observaciones: this.servicioDetalleExtra.observaciones,
          hora_ini: `${this.servicioDetalleExtra.fecha_ini} ${this.servicioDetalleExtra.hora_ini}`,
          hora_fin: `${this.servicioDetalleExtra.fecha_fin} ${this.servicioDetalleExtra.hora_fin}`,
          cant_horas: this.getTiempo(
            `${this.servicioDetalleExtra.fecha_ini} ${this.servicioDetalleExtra.hora_ini}`,
            `${this.servicioDetalleExtra.fecha_fin} ${this.servicioDetalleExtra.hora_fin}`
          ).cant_horas_mostrar,
        };
      }
      axios({
        method: "POST",
        url: "/api/cs/revision_servicios_internos/editReporteTrabajo",
        data: data,
      })
        .then((response) => {
          if (tipo_hora == "normal") {
            if (this.csDetalles.length - 1 > indice) {
              let next_data = {
                id: this.csDetalles[indice + 1].id,
                tipo_tiempo: this.csDetalles[indice + 1].tipo_tiempo,
                observaciones: this.csDetalles[indice + 1].observaciones,
                hora_ini: `${this.csDetalles[indice + 1].fecha_ini} ${
                  this.csDetalles[indice + 1].hora_ini
                }`,
                hora_fin: `${this.csDetalles[indice + 1].fecha_fin} ${
                  this.csDetalles[indice + 1].hora_fin
                }`,
                cant_horas: this.csDetalles[indice + 1].tiempo
                  .cant_horas_mostrar,
              };
              axios({
                method: "POST",
                url: "/api/cs/revision_servicios_internos/editReporteTrabajo",
                data: next_data,
              });
            }
            if (
              this.csDetalles.length > 1 &&
              this.csDetalles.length - 1 == indice
            ) {
              let data_previous = {
                id: this.csDetalles[indice - 1].id,
                tipo_tiempo: this.csDetalles[indice - 1].tipo_tiempo,
                observaciones: this.csDetalles[indice - 1].observaciones,
                hora_ini: `${this.csDetalles[indice - 1].fecha_ini} ${
                  this.csDetalles[indice - 1].hora_ini
                }`,
                hora_fin: `${this.csDetalles[indice - 1].fecha_fin} ${
                  this.csDetalles[indice - 1].hora_fin
                }`,
                cant_horas: this.csDetalles[indice - 1].tiempo
                  .cant_horas_mostrar,
              };
              axios({
                method: "POST",
                url: "/api/cs/revision_servicios_internos/editReporteTrabajo",
                data: data_previous,
              });
            }

            this.csDetalles[indice] = response.data[0];
            this.csDetalles[indice].tiempo = this.getTiempo(
              this.csDetalles[indice].hora_ini,
              this.csDetalles[indice].hora_fin
            );

            let inicio_f = this.csDetalles[indice].hora_ini.split(" ");
            let fin_f = this.csDetalles[indice].hora_fin.split(" ");
            this.csDetalles[indice].fecha_ini = inicio_f[0];
            this.csDetalles[indice].hora_ini = inicio_f[1];
            this.csDetalles[indice].fecha_fin = fin_f[0];
            this.csDetalles[indice].hora_fin = fin_f[1];
            this.getDatosBarra();
            this.estado_detalle = -1;
          }
          if (tipo_hora == "extra") {
            if (this.csDetallesExtras.length - 1 > indice) {
              let next_data = {
                id: this.csDetallesExtras[indice + 1].id,
                tipo_tiempo: this.csDetallesExtras[indice + 1].tipo_tiempo,
                observaciones: this.csDetallesExtras[indice + 1].observaciones,
                hora_ini: `${this.csDetallesExtras[indice + 1].fecha_ini} ${
                  this.csDetallesExtras[indice + 1].hora_ini
                }`,
                hora_fin: `${this.csDetallesExtras[indice + 1].fecha_fin} ${
                  this.csDetallesExtras[indice + 1].hora_fin
                }`,
                cant_horas: this.csDetallesExtras[indice + 1].tiempo
                  .cant_horas_mostrar,
              };
              axios({
                method: "POST",
                url: "/api/cs/revision_servicios_internos/editReporteTrabajo",
                data: next_data,
              });
            }
            this.csDetallesExtras[indice] = response.data[0];
            this.csDetallesExtras[indice].tiempo = this.getTiempo(
              this.csDetallesExtras[indice].hora_ini,
              this.csDetallesExtras[indice].hora_fin
            );

            let inicio_f = this.csDetallesExtras[indice].hora_ini.split(" ");
            let fin_f = this.csDetallesExtras[indice].hora_fin.split(" ");
            this.csDetallesExtras[indice].fecha_ini = inicio_f[0];
            this.csDetallesExtras[indice].hora_ini = inicio_f[1];
            this.csDetallesExtras[indice].fecha_fin = fin_f[0];
            this.csDetallesExtras[indice].hora_fin = fin_f[1];
            this.estado_detalle_extra = -1;
          }
          this.$parent.$refs.CsRevisionTarifasTrabajos.getTarifaServicio();
          this.$parent.cargando = false;
          if (!response.data.msg_error) {
            /* this.getIndex(this.servicioInterno); */
            this.id_actualizar_detalle = 0;
            this.$swal({
              icon: "success",
              title: "La edicion del reporte se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.form_tarifa.nombre = null;
            this.$swal({
              icon: "error",
              title: response.data.msg_error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async addDetalle(tipo_hora) {
      const inicio_servicio = await this.getHoraInicio(tipo_hora);
      this.limpiarDetalle(tipo_hora);
      if (tipo_hora == "normal") {
        this.servicioDetalle.cs_servicio_interno_id = this.$parent.servicioInterno.id;
        this.servicioDetalle.fecha_ini = inicio_servicio.fecha;
        this.servicioDetalle.hora_ini = inicio_servicio.hora;
        if (this.$parent.servicioInterno.persona_2_id) {
          this.servicioDetalle.fecha_fin = this.$parent.servicioInterno.fecha_ini1;
          this.servicioDetalle.hora_fin = "18:00";
        } else {
          this.servicioDetalle.fecha_fin = this.$parent.servicioInterno.fecha_fin1;
          this.servicioDetalle.hora_fin = this.$parent.servicioInterno.hora_fin1;
        }
        this.estado_detalle = 1;
      }

      if (tipo_hora == "extra") {
        this.servicioDetalleExtra.cs_servicio_interno_id = this.$parent.servicioInterno.id;
        this.servicioDetalleExtra.fecha_ini = inicio_servicio.fecha;
        this.servicioDetalleExtra.hora_ini = inicio_servicio.hora;
        this.servicioDetalleExtra.fecha_fin = this.$parent.servicioInterno.fecha_fin1;
        this.servicioDetalleExtra.hora_fin = null;
        this.estado_detalle_extra = 1;
      }
    },

    async destroyDetalle(index, tipo_hora) {
      if (tipo_hora == "normal") {
        this.$swal({
          title: "Quitar reporte de trabajo.",
          text:
            "Está seguro de quitar este reporte? Si tiene horas extras se perderan.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Quitar!",
        }).then(async (result) => {
          if (result.value) {
            this.csDetalles.splice(index, 1);
            //this.validaFinReportes();
            await this.getDatosBarra();
            await this.saveDetalleTotal();
          }
        });
      }
      if (tipo_hora == "extra") {
        this.$swal({
          title: "Quitar reporte de trabajo.",
          text: "Está seguro de quitar este reporte?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Quitar!",
        }).then(async (result) => {
          if (result.value) {
            this.csDetallesExtras.splice(index, 1);
            this.fechaLimite = false;
            await this.getDatosBarra();
            await this.saveDetalleTotal();
          }
        });
      }
    },

    getHoraInicio(tipo_hora) {
      if (tipo_hora == "normal") {
        if (this.csDetalles.length > 0) {
          const fecha_final_ultimo_servicio = new Date(
            `${this.csDetalles[this.csDetalles.length - 1].fecha_fin} ${
              this.csDetalles[this.csDetalles.length - 1].hora_fin
            }`
          );
          return {
            fecha: moment(fecha_final_ultimo_servicio.getTime()).format(
              "YYYY-MM-DD"
            ),
            hora: moment(fecha_final_ultimo_servicio.getTime()).format("HH:mm"),
          };
        } else {
          return {
            fecha: this.$parent.servicioInterno.fecha_ini1,
            hora: this.$parent.servicioInterno.hora_ini1,
          };
        }
      }

      if (tipo_hora == "extra") {
        if (this.csDetallesExtras.length > 0) {
          const fecha_final_ultimo_servicio = new Date(
            `${
              this.csDetallesExtras[this.csDetallesExtras.length - 1].fecha_fin
            } ${
              this.csDetallesExtras[this.csDetallesExtras.length - 1].hora_fin
            }`
          );
          return {
            fecha: moment(fecha_final_ultimo_servicio.getTime()).format(
              "YYYY-MM-DD"
            ),
            hora: moment(fecha_final_ultimo_servicio.getTime()).format("HH:mm"),
          };
        } else {
          return {
            fecha: this.$parent.servicioInterno.fecha_fin1,
            hora: this.$parent.servicioInterno.hora_fin1,
            //hora: "18:00",
          };
        }
      }
    },

    async saveDetalle(tipo_hora) {
      let me = this;
      if (tipo_hora == "normal") {
        me.servicioDetalle.tiempo = this.getTiempo(
          `${this.servicioDetalle.fecha_ini} ${this.servicioDetalle.hora_ini}`,
          `${this.servicioDetalle.fecha_fin} ${this.servicioDetalle.hora_fin}`
        );
        me.servicioDetalle.tipo_hora = 1;
        me.servicioDetalle.conductor_id = me.$parent.servicioInterno.persona_id;
        me.csDetalles.push(me.servicioDetalle);

        me.estado_detalle = -1;
        await this.getDatosBarra();
        await this.saveDetalleTotal();
        //await this.validaFinReportes();
      }
      if (tipo_hora == "extra") {
        me.servicioDetalleExtra.tiempo = this.getTiempo(
          `${this.servicioDetalleExtra.fecha_ini} ${this.servicioDetalleExtra.hora_ini}`,
          `${this.servicioDetalleExtra.fecha_fin} ${this.servicioDetalleExtra.hora_fin}`
        );
        me.servicioDetalleExtra.tipo_hora = 2;
        me.servicioDetalleExtra.conductor_id =
          me.$parent.servicioInterno.persona_id;
        me.csDetallesExtras.push(me.servicioDetalleExtra);
        me.estado_detalle_extra = -1;
        //await this.validarFechaLimite();
        await this.getDatosBarra();
        await this.saveDetalleTotal();
      }
    },

    async saveDetalleTotal() {
      this.csDetalles.forEach((det) => {
        det.cant_horas = det.tiempo.cant_horas_mostrar;
        det.hora_ini = `${det.fecha_ini} ${det.hora_ini}`;
        det.hora_fin = `${det.fecha_fin} ${det.hora_fin}`;
      });
      this.csDetallesExtras.forEach((det) => {
        det.cant_horas = det.tiempo.cant_horas_mostrar;
        det.hora_ini = `${det.fecha_ini} ${det.hora_ini}`;
        det.hora_fin = `${det.fecha_fin} ${det.hora_fin}`;
      });
      await axios({
        method: "POST",
        url: "/api/cs/servicios/detalle",
        data: {
          csDetalles: this.csDetalles,
          csDetallesExtras: this.csDetallesExtras,
          cs_servicio_interno_id: this.$parent.servicioInterno.id,
        },
      })
        .then(async () => {
          await this.getIndex(this.$parent.servicioInterno);
          //this.guardar++;
        })
        .catch((e) => {
          this.message += e.message;
        });
    },

    toFixed(num) {
      return Number.parseFloat(num).toFixed(2);
    },
  },
  async mounted() {
    await this.getTipoTiempo();
  },
};
</script>
